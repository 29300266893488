import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import Play from "@assets/icons/play.svg";
import Pause from "@assets/icons/pause.svg";
import style from "./style.scss";
import cn from "classnames";

export default ({
  className,
  withPause,
  children,
  settings,
  active,
  focused,
}) => {
  const [paused, setPaused] = useState(false);
  const slider = useRef(null);

  const handlePlay = (slider) => {
    slider.slickPlay();
    setPaused(false);
  };

  const handlePause = (slider) => {
    slider.slickPause();
    setPaused(true);
  };

  const handleKeyPress = (e, slider, action) => {
    if (e.key === "Enter") {
      action === "play" ? handlePlay(slider) : handlePause(slider);
    }
  };

  useEffect(() => {
    slider.current.slickGoTo(active);
  }, [active]);

  useEffect(() => {
    focused ? slider?.current?.slickPause() : slider?.current?.slickPlay();
  }, [focused]);

  const defaultSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    draggable: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",
    appendDots: (dots) => (
      <div>
        {dots}
        {withPause ? (
          paused ? (
            <Play
              tabIndex="0"
              onClick={() => handlePlay(slider.current)}
              onKeyPress={(e) => handleKeyPress(e, slider.current, "play")}
            />
          ) : (
            <Pause
              tabIndex="0"
              onClick={() => handlePause(slider.current)}
              onKeyPress={(e) => handleKeyPress(e, slider.current, "pause")}
            />
          )
        ) : null}
      </div>
    ),
    ...settings,
  };

  return (
    <div className={cn(style.carousel, className)}>
      <Slider ref={slider} {...defaultSettings}>
        {children}
      </Slider>
    </div>
  );
};
