export default {
  title: "Pride365+",
  links: [
    {
      label: "Worksheet",
      link: "/worksheet",
    },
    {
      label: "Podcasts",
      link: "/podcasts",
    },
    {
      label: "Facts",
      link: "/fact-sheets",
    },
    {
      label: "How To Be An Ally",
      link: "/how-to-be-an-ally",
    },
    {
      label: "Resources",
      link: "/resource-guides",
    },
    ,
    {
      label: "Transgender Support",
      link: "/transgender-support",
    },
    {
      label: "Terminology & Pronouns",
      link: "/terminology-&-pronouns",
    },
    {
      label: "Poster & Flyer",
      link: "/poster-&-flyer",
      smaller: true,
    },
    {
      label: "Email",
      link: "/emails",
      smaller: true,
    },
    {
      label: "Socials",
      link: "/media",
      smaller: true,
    },
  ],
};
