import React from "react";
import { useLocation } from "react-router-dom";
import LinkButton from "@atom/LinkButton";
import style from "./style.scss";
import cn from "classnames";

export default ({ className }) => {
  let location = useLocation().pathname;
  const first = location.split("/")[1];
  const second = location.split("/")[2];

  return (
    <div className={cn(style.breadcrumbs, className)}>
      <LinkButton to={"/"} className={cn(style.linkButton)}>
        {"Home"}
      </LinkButton>
      <label>/</label>
      {second ? (
        <>
          <label />
          <LinkButton to={"/" + first} className={cn(style.linkButton)}>
            {first.replace(/-/g, " ")}
          </LinkButton>
          <label>/</label>
          <label className={cn(style.second)}>
            {second.replace(/-/g, " ")}
          </label>
        </>
      ) : (
        <label>{first.replace(/-/g, " ")}</label>
      )}
    </div>
  );
};
