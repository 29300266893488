import React from "react";
import { Heading, LabelwithEmphasis } from "@atom/Typography";
import style from "./style.scss";
import cn from "classnames";

const details = {
  title: "Substance use and the LGBTQ+ community:",
  subtitle: "5 things to know",
  body1:
    "People of all genders and sexual orientations may use legal and illegal substances with the potential for abuse. They may be curious or feel socially pressured. They may be chasing pleasure and disinhibition. They might seek to escape reality. Or they might be trying to cope with anxiety, stress or depression.\n\n<strong>Substance use is a far-reaching topic.</strong> But there are some particular ways it affects the LGBTQ+ community. Dr. Dustin Nowaskie and Dr. Alex Keuroghlian explore this topic on the latest episode of the Pride 365+ podcast.",
  title2: "ways substance use affects \nLGBTQ+ people",
  list1: [
    "<strong>LGBTQ+ people have higher rates of substance use.</strong> This includes alcohol and drugs. They are about twice as likely to use marijuana and opioids compared to heterosexual individuals.2  LGBTQ+ youth are two to three times more likely than their peers to use cocaine, ecstasy, crystal meth or heroin.",
    "<strong>Discrimination plays a role.</strong>  From early childhood on, many LGBTQ+ people experience discrimination. Stresses like dealing with less-than-accepting family and friends, microaggressions and outright prejudice can affect mental and physical health. These factors can lead people to cope in ways that aren’t healthy.",
  ],
  quote1: `“Believing all the negative things that society has to say about one’s sexual orientation, gender identity and gender expression … [is linked to] much higher prevalence of depressive disorders, anxiety disorders, post-traumatic stress disorder and substance use disorders.” <br><strong>— Dr. Alex Keuroghlian</strong>`,
  list2: [
    "<strong>LGBTQ+ gatherings often include substances.</strong> For many people, alcohol is part of everyday culture. It’s common at hangouts, sporting events and other social gatherings. For LGBTQ+ people, this is especially true. Many have long considered bars and clubs important parts of their social life where they have been allowed to explore their identities. These locations serve an important political and community role as well, and yet despite the positives, people who live near LGBTQ+ bars have higher rates of substance use disorders.",
    "<strong>Not all substance use disorders are the same.</strong>It’s important to note the effect substance use has on one’s life and that functionality varies. Substance use can mean having a drink occasionally with dinner. For many people, that use wouldn’t negatively affect their life. Use isn’t the same as a substance use disorder. A disorder means someone’s life is being impacted negatively. Experts use 11 criteria to determine if someone has a substance use disorder. If so, it can be mild, moderate or severe.",
    "<strong>You can make an impact.</strong> Be mindful: Whether you’re looking forward to next year’s Pride festivities or planning an event right now, consider creating a more inclusive environment. If a gathering is centered around substance use, it can exclude those who are in recovery. It can also exclude people who live a substance-free lifestyle. Pick locations other than bars. Consider an alcohol-free event. Or, if you’re going to serve drinks, offer great nonalcoholic beverages too. If you’re looking for sponsors for your event, think about what they represent. Is there a better option than an alcohol, cigarette/vaping or cannabis company? ",
  ],
  body2:
    "<strong>Remember that no one should have to tell you if they’re in recovery.</strong>They also shouldn’t have to explain why they choose not to use substances. As a host, take the initiative. Consider the lived experiences of everyone in the LGBTQ+ community. Create an environment that makes all people feel comfortable. It’s an easy way to be more inclusive, and it might even help reduce substance use in the community",
  quote2: `“Never underestimate the impact you can have changing or even saving an LGBTQ+ person’s life by approaching them with cultural humility, by listening, by adopting a nonjudgmental stance and by educating yourself.”<br><strong>— Dr. Alex Keuroghlian</strong>`,
};
export default () => (
  <div className={cn(style.episode)}>
    <Heading type={"h3"} weight={700} className={cn(style.centered)}>
      {details.title}
    </Heading>
    <Heading type={"h1"} weight={900} className={cn(style.centered, style.sub)}>
      {details.subtitle}
    </Heading>
    <LabelwithEmphasis className={cn(style.body)}>
      {details.body1}
    </LabelwithEmphasis>
    <div className={cn(style.rowTitle)}>
      <div className={cn(style.number)}>5</div>
      <Heading type={"h2"} weight={900}>
        {details.title2}
      </Heading>
    </div>

    <ol className={cn(style.list)}>
      {details.list1.map((value, i) => (
        <li key={i}>
          <LabelwithEmphasis>{value}</LabelwithEmphasis>
        </li>
      ))}
    </ol>
    <LabelwithEmphasis className={cn(style.quote)}>
      {details.quote1}
    </LabelwithEmphasis>
    <ol className={cn(style.list)} start={3}>
      {details.list2.map((value, i) => (
        <li key={i}>
          <LabelwithEmphasis>{value}</LabelwithEmphasis>
        </li>
      ))}
    </ol>

    <LabelwithEmphasis className={cn(style.body)}>
      {details.body2}
    </LabelwithEmphasis>

    <LabelwithEmphasis className={cn(style.quote)}>
      {details.quote2}
    </LabelwithEmphasis>
  </div>
);
