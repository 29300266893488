export default {
  heading: {
    title: "We’re LGBTQ+.",
    subtitle:
      "In search of an open, safe and respectful world. <span>People.</span>",
    description:
      "Despite the progress made to legally protect the civil rights of LGBTQ+ people, discrimination, prejudice, harassment and isolation continue to threaten and harm the well-being of many in the community. To help advance, honor and protect the civil rights of all people, we need to understand the too often grim reality of what our LGBTQ+ colleagues and loved ones face.",
    pdf: "/assets/pdf/P365+_LGBTQ+_Fact_Sheet.pdf",
  },
  challenges: "The Challenges",
  challengesDescription:
    "<a target='_blank' href='https://www.psychiatry.org/File Library/Psychiatrists/Cultural-Competency/Mental-Health-Disparities/Mental-Health-Facts-for-LGBTQ.pdf'>LGBTQ</a> individuals are more likely to be victims of violence compared with their heterosexual peers.\n\nThe risk of experiencing violence is even higher for undocumented and racial/ethnic minority LGBTQ+ people.",
  challengesStats: [
    {
      percent: 51,
      sublabel: "of LGBTQ+ people have been sexually harassed.",
    },
    {
      percent: 51,
      sublabel:
        "of LGBTQ+ people have experienced violence because of their sexuality or gender identity.",
    },
    {
      percent: 57,
      sublabel:
        "of LGBTQ+ people have been threatened or non-sexually harassed.",
    },
  ],
  challengesLabeledImages: [
    {
      img: require("@assets/images/toolkits/challenges1.png"),
      sublabel:
        "Nearly <a target='_blank' href='https://www.justice.gov/hatecrimes/hate-crime-statistics'>1 in 5</a> reported hate crimes are motivated by anti-LGBTQ+ bias. Of those, the majority (roughly 60%) target gay men.",
    },
    {
      img: require("@assets/images/toolkits/challenges2.png"),
      sublabel:
        "LGBTQ+ high school students experience higher prevalence of sexual violence and dating violence when compared with heterosexual students.",
    },
    {
      img: require("@assets/images/toolkits/challenges3.png"),
      sublabel:
        "<a target='_blank' href='https://www.cdc.gov/mmwr/volumes/69/su/su6901a3.htm'>LGBTQ+</a> adults experience significantly higher prevalence of rape, physical violence and/or stalking when compared with heterosexuals. ",
    },
  ],
  mental: "Mental health and substance use disorders",
  mentalList: [
    "<a target='_blank' href='https://www.samhsa.gov/data/sites/default/files/cbhsq-reports/NSDUHNationalFindingsReport2018/NSDUHNationalFindingsReport2018.pdf'>1 in 5</a> U.S. adults experience mental illness – LGBTQ individuals are more than twice as likely as heterosexuals to have a mental health disorder in their lifetime.",
    "<a target='_blank' href='https://mhanational.org/issues/lgbtq-communities-and-mental-health' >LGBTQ+</a> teens are 6X more likely to experience symptoms of depression than non-LGBTQ+ identifying teens.",
    "The rate of suicide attempts is four times greater for lesbian, gay, and bisexual youth and two times greater for questioning youth than that of heterosexual <a target='_blank' href='https://www.psychiatry.org/File Library/Psychiatrists/Cultural-Competency/Mental-Health-Disparities/Mental-Health-Facts-for-LGBTQ.pdf'>youth</a>.",
    "Of LGBTQ older adults, 31% report depressive symptoms, while 39% report serious thoughts of taking their own <a target='_blank' href='https://www.psychiatry.org/File Library/Psychiatrists/Cultural-Competency/Mental-Health-Disparities/Mental-Health-Facts-for-LGBTQ.pdf'>lives</a>.",
    "48% of <a target='_blank' href='https://mhanational.org/issues/lgbtq-communities-and-mental-health'>transgender</a> adults considered suicide in the last year, compared to 4 percent of the overall U.S. population.",
    "Transgender individuals who identify as African American/Black, Hiaic/Latino, American Indian/Alaska Native, or multiracial are at increased risk of suicide attempts than white transgender <a target='_blank' href='https://www.psychiatry.org/File Library/Psychiatrists/Cultural-Competency/Mental-Health-Disparities/Mental-Health-Facts-for-LGBTQ.pdf'>individuals</a>.",
    "Women who identify as lesbian and bisexual are more than twice as likely to engage in heavy (alcohol) drinking than heterosexual <a target='_blank' href='https://www.psychiatry.org/File Library/Psychiatrists/Cultural-Competency/Mental-Health-Disparities/Mental-Health-Facts-for-LGBTQ.pdf'>women</a>.",
  ],
  disparities: "Disparities",
  disparitiesLabeledImages: [
    {
      img: require("@assets/images/toolkits/disparities1.png"),
      sublabel:
        "Many LGBTQ people have reported experiencing stigma and discrimination when accessing health services, leading some individuals to delay necessary health care or forego it altogether.",
    },
    {
      img: require("@assets/images/toolkits/disparities2.png"),
      sublabel:
        "<a target='_blank' href='https://mhanational.org/issues/lgbtq-communities-and-mental-health'>Approximately</a> 8% of LGBTQ+ individuals and nearly 27% of transgender individuals report being denied needed health care outright.",
    },
    {
      img: require("@assets/images/toolkits/disparities3.png"),
      sublabel:
        "59% of <a target='_blank' href='https://mhanational.org/issues/lgbtq-communities-and-mental-health'>LGBTQ+</a> people feel they have fewer employment opportunities and 50 percent believe they are paid less than non-LGBTQ+ people.",
    },
  ],
};
