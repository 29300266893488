import React from "react";
import Anchor from "@atom/Anchor";
import { Heading, LabelwithEmphasis } from "@atom/Typography";
import style from "./style.scss";
import cn from "classnames";

export const PdfDownload = ({ pdf, children, className }) => (
  <Anchor
    href={pdf}
    download={true}
    target="_blank"
    onClick={
      "var that=this;_gaq.push([‘_trackEvent’,’Download’,’PDF’,this.href]);setTimeout(function(){location.href=that.href;},200);return false;"
    }
    className={className}
  >
    {children}
  </Anchor>
);

export default ({ items, imageFooter }) => (
  <div className={cn(style.imageLabel)}>
    {items.map(
      ({ label, sublabel, img, image, pdf, percent, downloadLabel }, i) => (
        <div key={i} className={cn(style.item)}>
          {percent ? (
            <Heading className={cn(style.percent)}>
              {percent}
              <span>%</span>
            </Heading>
          ) : image ? (
            <PdfDownload {...{ pdf }}>
              <img {...image} alt={label} className={style.image} />
            </PdfDownload>
          ) : (
            <div
              className={cn(style.roundImage)}
              style={{ backgroundImage: `url(${img})` }}
            />
          )}
          {label ? (
            <Heading type={"h4"} className={cn(style.label)}>
              {label}
            </Heading>
          ) : null}
          {downloadLabel && (
            <PdfDownload {...{ pdf }}>{downloadLabel}</PdfDownload>
          )}
          <LabelwithEmphasis
            className={cn(style.sublabel, label && style.withLabel)}
          >
            {sublabel}
          </LabelwithEmphasis>
        </div>
      )
    )}
    {imageFooter && <img {...imageFooter} className={style.imageFooter} />}
  </div>
);
