import React, { useState } from "react";
import Anchor from "@atom/Anchor";
import LinkButton from "@atom/LinkButton";
import Modal from "@atom/Modal";
import ProgressiveImage from "@atom/ProgressiveImage";
import { Heading } from "@atom/Typography";
import style from "./style.scss";
import cn from "classnames";

export default ({ title, subtitle, background, items, trevor }) => {
  const [selected, setSelected] = useState();
  const [open, setOpen] = useState();

  return (
    <div className={cn(style.worksheet)}>
      <div className={cn(style.top)}>
        <div className={cn(style.content)}>
          <ProgressiveImage {...background} className={cn(style.bg)} />
          <div className={cn(style.title)}>
            <div className={cn(style.inner)}>
              <Heading type={"h2"} weight={900}>
                {title}
              </Heading>
              <p>{subtitle}</p>
            </div>
            <ProgressiveImage {...background} className={cn(style.bgMobile)} />
            <div className={cn(style.hr)} />
          </div>
          <div className={cn(style.items)}>
            {items.map((value, i) => (
              <div key={i} className={cn(style.item)}>
                <ProgressiveImage {...value.image} />
                <div className={cn(style.text)}>
                  <div className={cn(style.upper)}>
                    <Heading type={"h5"}>{value.title}</Heading>
                    <label>{value.subtitle}</label>
                  </div>
                  {value.pdf ? (
                    <Anchor
                      href={value.pdf}
                      onClick={
                        "_gaq.push([‘_trackEvent’,’Download’,’PDF’,this.href])"
                      }
                    >
                      {value.pdfLabel}
                    </Anchor>
                  ) : (
                    <LinkButton to={value.link.url}>
                      {value.link.label}
                    </LinkButton>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={cn(style.bottom)}>
        <div className={cn(style.content)}>
          <div className={cn(style.logo)}>
            <ProgressiveImage {...trevor.logo} />
            <Anchor href={trevor.link.url}>{trevor.link.label}</Anchor>
          </div>
          {open ? (
            <Modal close={() => setOpen(false)}>
              <ProgressiveImage
                {...trevor.images[selected - 1]}
                {...trevor.images[selected - 1].larger}
              />
            </Modal>
          ) : null}
          {trevor.images.map((value, i) => (
            <div
              key={i}
              className={cn(style.stats)}
              onClick={() => {
                setSelected(i + 1);
                setOpen(true);
              }}
            >
              <ProgressiveImage {...value} {...value.smaller} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
