import React from "react";
import List from "@atom/List";
import { Heading, LabelwithEmphasis } from "@atom/Typography";
import ImagewithLabel from "@molecule/ImagewithLabel";
import TitleDescription from "@molecule/TitleDescription";
import data from "./data";
import style from "./style.scss";
import cn from "classnames";

export default () => (
  <div className={cn(style.factSheets)}>
    <TitleDescription {...data.heading} />
    <Heading type={"h3"} withMargin={true}>
      {data.challenges}
    </Heading>
    <LabelwithEmphasis>{data.challengesDescription}</LabelwithEmphasis>
    <ImagewithLabel items={data.challengesStats} />
    <ImagewithLabel items={data.challengesLabeledImages} />
    <Heading type={"h3"} withMargin={true}>
      {data.mental}
    </Heading>
    <List items={data.mentalList} />
    <Heading type={"h3"} withMargin={true}>
      {data.disparities}
    </Heading>
    <ImagewithLabel items={data.disparitiesLabeledImages} />
  </div>
);
