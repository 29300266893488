import React from "react";
import style from "./style.scss";
import cn from "classnames";

export default ({ label, name, value, className, ...rest }) => (
  <div className={cn(style.textarea, className)}>
    <label>{label}:</label>
    <textarea
      id={name}
      name={name}
      rows="5"
      cols="33"
      maxlength="250"
      {...rest}
    ></textarea>
  </div>
);
