import React from "react";
import List from "@atom/List";
import { Heading, LabelwithEmphasis } from "@atom/Typography";
import TitleDescription from "@molecule/TitleDescription";
import data from "./data";
import style from "./style.scss";
import cn from "classnames";

export default () => (
  <div className={cn(style.ally)}>
    <TitleDescription {...data.heading} />
    <Heading type={"h3"} withMargin={true}>
      {data.ally}
    </Heading>
    {data.allyList.map((value, i) => (
      <LabelwithEmphasis key={i} primary={true} className={cn(style.allyList)}>
        {value}
      </LabelwithEmphasis>
    ))}
    <Heading type={"h3"} withMargin={true}>
      {data.biases}
    </Heading>
    {data.biasesDescription}
    <List items={data.biasesList} />
    <p>{data.biasesFooter}</p>
    <div className={cn(style.actions)}>
      {data.biasesActions.map(({ label, description }, i) => (
        <div key={i} className={cn(style.item)}>
          <Heading type={"h5"} weight={900}>
            {label}
          </Heading>
          <div className={cn(style.hr)} />
          <p>{description}</p>
        </div>
      ))}
    </div>
  </div>
);
