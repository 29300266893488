import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import Footer from "@molecule/Footer";
import Menu from "@organism/Menu";
import Home from "./Home";
import Podcasts from "./Podcasts";
import Support from "./Support";
import ToolkitMaterials from "./ToolkitMaterials";
import Worksheet from "./Worksheet";

const Screen = () => {
  let location = useLocation().pathname;

  document.addEventListener("DOMContentLoaded", (_event) => {
    document
      .querySelectorAll('a[href^="https"], a[href^="http"]')
      .forEach((anchor) => anchor.setAttribute("rel", "noreferrer"));
  });

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        position: "relative",
        zIndex: 1,
      }}
    >
      <Menu noBG={location === "/"} />
      <Switch>
        <Route exact path={"/"} component={Home} />
        <Route path={"/(poster-&-flyer|emails|media)"} component={Support} />
        <Route
          path={
            "/(fact-sheets|how-to-be-an-ally|resource-guides|transgender-support|terminology-&-pronouns)"
          }
          component={ToolkitMaterials}
        />
        <Route path={"/podcasts"} component={Podcasts} />
        <Route path={"/worksheet"} component={Worksheet} />
        <Redirect to={"/"} />
      </Switch>
      {/* <Footer
        mainText={
          location === "/" ||
          location === "/podcasts" ||
          location === "/poster-&-flyer" ||
          location === "/emails" ||
          location === "/worksheet" ||
          location === "/media"
        }
      /> */}
    </div>
  );
};

export default Screen;
