import React from "react";
import style from "./style.scss";
import cn from "classnames";

export default ({
  children,
  pageCardClass,
  className,
  withBorder,
  withBothShadow,
}) => (
  <div
    className={cn(
      style.pageCard,
      withBothShadow && style.leftShadow,
      pageCardClass
    )}
  >
    <div
      className={cn(style.content, withBorder && style.withBorder, className)}
    >
      {children}
    </div>
  </div>
);
