import React, { useState } from "react";
import Anchor from "@atom/Anchor";
import ProgressiveImage from "@atom/ProgressiveImage";
import Carousel from "@atom/Carousel";
import style from "./style.scss";
import cn from "classnames";

const Banner = ({
  src,
  mobile,
  placeholder,
  mobilePlaceholder,
  link,
  href,
  isVisible,
  onFocus,
  onBlur,
}) => (
  <Anchor
    {...{ onFocus, onBlur }}
    href={link ? link : href}
    target={link ? "_self" : "_blank"}
    className={cn(style.root)}
    tabIndex={isVisible ? 0 : -1}
  >
    <ProgressiveImage
      {...{ src, placeholder }}
      alt={"Banner"}
      className={cn(style.image)}
      width={1920}
      height={856}
    />
    <ProgressiveImage
      src={mobile}
      placeholder={mobilePlaceholder}
      alt={"Banner"}
      className={cn(style.imageMobile)}
      width={599}
      height={643}
    />
  </Anchor>
);

const carouselStyle = { height: 9, width: 9 };

export default ({ banners }) => {
  const [currentBanner, setCurrentBanner] = useState(0);
  const [sliderFocus, setSliderFocus] = useState(false);

  function handleKeyPress(e, i) {
    if (e.key === "Enter") {
      setCurrentBanner(i);
    }
  }

  return (
    <Carousel
      className={cn(style.banner)}
      focused={sliderFocus}
      active={currentBanner}
      settings={{
        dots: true,
        fade: true,
        autoplay: true,
        autoplaySpeed: 4500,
        initialSlide: 0,
        customPaging: (i) => (
          <div
            style={carouselStyle}
            tabIndex={0}
            onKeyPress={(e) => handleKeyPress(e, i)}
          />
        ),
        afterChange: setCurrentBanner,
      }}
    >
      {banners.map((value, i) => (
        <Banner
          {...value}
          key={i}
          isVisible={currentBanner === i}
          onFocus={() => {
            setSliderFocus(true);
          }}
          onBlur={() => {
            setSliderFocus(false);
          }}
        />
      ))}
    </Carousel>
  );
};
