import React from "react";
import LinkButton from "@atom/LinkButton";
import ProgressiveImage from "@atom/ProgressiveImage";
import style from "./style.scss";
import cn from "classnames";

const onClick = () =>
  document.querySelector("#supportMaterials").scrollIntoView({
    behavior: "smooth",
  });

const Image = ({ image, link, hash }) =>
  hash ? (
    <a id={hash} onClick={onClick}>
      <ProgressiveImage {...image} />
    </a>
  ) : (
    <LinkButton to={link}>
      <ProgressiveImage {...image} />
    </LinkButton>
  );

export default ({ items }) => {
  const Column = ({ index }) => (
    <div className={cn(style.column)}>
      {index.map((value, i) => (
        <Image {...items[value]} key={i} />
      ))}
    </div>
  );

  return (
    <div className={cn(style.masonry)}>
      <div className={cn(style.wide)}>
        <Column index={[0, 3]} />
        <Column index={[1, 4]} />
        <Column index={[2, 5]} />
      </div>
      <div className={cn(style.semi)}>
        <Column index={[0, 1, 2]} />
        <Column index={[3, 4, 5]} />
      </div>
    </div>
  );
};
