import React, { useState } from "react";
// import Icon from "@atom/Icon";
import style from "./style.scss";
import cn from "classnames";

const Quote = ({ quote }) => {
  const [show, setShow] = useState(false);
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;

  function copy() {
    if (isIE) {
      window.clipboardData.setData("Text", quote);
    } else {
      navigator.clipboard.writeText(quote);
    }
    setShow(true);
    setTimeout(() => setShow(false), 720);
  }

  return (
    <div className={cn(style.item)} onClick={() => copy()}>
      <div className={cn(style.copied, show && style.show)}>{"Copied"}</div>
      {quote}
    </div>
  );
};

export default ({ items, itemTitle }) => (
  <div className={cn(style.media)}>
    <div className={cn(style.hr)} />
    <label> {itemTitle}</label>
    {items.map((quote, i) => (
      <Quote {...{ quote }} key={i} />
    ))}
  </div>
);
