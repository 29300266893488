export default {
  sourcesLabel: "Sources",
  items: [
    {
      title: "Facts",
      link: "/fact-sheets",
      background: require("@assets/images/toolkits/toolkit1_bg.png"),
      placeholder: require("@assets/images/toolkits/toolkit1_thumb.png"),
      sources: [
        {
          label:
            "Centers for Disease Control and Prevention: Youth Risk Behavior Surveillance Systems FAQs. ",
          sublabel: "Accessed 4/3/21",
          link: "https://www.cdc.gov/healthyyouth/data/yrbs/faq.htm?s_cid=hy-YRBS-2020-4",
        },
        {
          label:
            "Centers for Disease Control and Prevention: Trends in Violence Victimization and Suicide Risk by Sexual Identity Among High School Students — Youth Risk Behavior Survey, United States, 2015–2019. ",
          sublabel: "Accessed 4/3/21.",
          link: "https://www.cdc.gov/mmwr/volumes/69/su/su6901a3.htm",
        },
        {
          label:
            "National Alliance on Mental Illness. Identity and Cultural Dimensions: LGBTQI.",
          sublabel: "Accessed 4/3/21",
          link: "https://www.nami.org/Your-Journey/Identity-and-Cultural-Dimensions/LGBTQI",
        },
        {
          label:
            "Centers for Disease Control and Prevention. Intimate Partner Violence.",
          sublabel: "Accessed 4/3/21",
          link: "https://www.cdc.gov/violenceprevention/intimatepartnerviolence/index.html",
        },
        {
          label:
            "Centers for Disease Control and Prevention. Intimate Partner Violence PDF.",
          sublabel: "Accessed 4/3/21",
          link: "https://www.cdc.gov/violenceprevention/pdf/ipv/IPV-factsheet_2020_508.pdf",
        },
        {
          label: "Federal Bureau of Investigators: 2018 Hate Crime Statistics.",
          sublabel: "Accessed 4/3/21.",
          link: "https://ucr.fbi.gov/hate-crime/2018/tables/table-1.xls",
        },
        {
          label:
            "American Psychiatric Association: Mental Health Disparities: LGBTQ.",
          sublabel: "Accessed 4/3/21.",
          link: "https://www.psychiatry.org/File Library/Psychiatrists/Cultural-Competency/Mental-Health-Disparities/Mental-Health-Facts-for-LGBTQ.pdf",
        },
        {
          label:
            "American Psychological Association: Lesbian, Gay, Bisexual and Transgender Health.",
          sublabel: "Accessed 4/3/21.",
          link: "https://www.apa.org/pi/lgbt/resources/lgbt-health",
        },
        {
          label: "Mental Health America: LGBTQ+ Communities And Mental Health.",
          sublabel: "Accessed 4/3/21.",
          link: "https://mhanational.org/issues/lgbtq-communities-and-mental-health",
        },
        {
          label:
            "American Psychological Association: Sexual Orientation and Homosexuality.",
          sublabel: "Accessed 4/3/21.",
          link: "https://www.apa.org/topics/lgbtq/orientation",
        },
      ],
    },
    {
      title: "How to be an ally",
      link: "/how-to-be-an-ally",
      background: require("@assets/images/toolkits/toolkit2_bg.png"),
      placeholder: require("@assets/images/toolkits/toolkit2_thumb.png"),
      sources: [
        {
          label:
            "The Trevor Project: A Guide to Being an Ally to Transgender and Nonbinary Youth.",
          sublabel: "Accessed 4/1/21",
          link: "https://www.thetrevorproject.org/resources/trevor-support-center/a-guide-to-being-an-ally-to-transgender-and-nonbinary-youth/",
        },
        {
          label: "GLAAD: 10 Ways to Be an Ally and a Friend.",
          sublabel: "Accessed 4/1/21",
          link: "https://www.glaad.org/resources/ally/2",
        },
        {
          label: "Youth Engaged 4 Change: Being an Ally to LGBT People.",
          sublabel: "Accessed 4/1/21",
          link: "https://engage.youth.gov/resources/being-ally-lgbt-people",
        },
        {
          label:
            "Centers for Disease Control and Prevention: Stigma and Discrimination.",
          sublabel: "Accessed 4/1/21",
          link: "https://www.cdc.gov/msmhealth/stigma-and-discrimination.htm#Stigma",
        },
        {
          label:
            "American Psychological Association: Answers to Your Questions about Transgender people, gender identify and gender expression.",
          sublabel: "Accessed 3/20/21",
          link: "https://www.apa.org/topics/lgbtq/transgender.pdf",
        },
        {
          label: "Centers for Disease Control and Prevention: LGBT Health.",
          sublabel: "Accessed 3/20/21",
          link: "https://www.cdc.gov/lgbthealth/youth.htm",
        },
        {
          label:
            "PFLAG: Straight for Equality: Guide to being a straight ally.",
          sublabel: "Accessed 3/20/21",
          link: "https://pflag.org/sites/default/files/2020-Straight Ally Guide Revised.pdf",
        },
      ],
    },
    {
      title: "Resources",
      link: "/resource-guides",
      background: require("@assets/images/toolkits/toolkit3_bg.png"),
      placeholder: require("@assets/images/toolkits/toolkit3_thumb.png"),
      substanceLabel: "Substance Use Disorders",
      helpline: [
        'Substance Use Helpline: <a href="tel:+1-877-627-1041"}>(877) 627-1041</a>',
        'SAMHSA’s National Helpline: <a href="tel:+1-800-662-4357">1-800-662-HELP</a> (4357) and TTY <a href="tel:+1-800-487-4889"}>1-800-487-4889</a>',
      ],
      sources: [
        {
          label:
            "National Alliance on Mental Illness. Identity and Cultural Dimensions: LGBTQI.",
          sublabel: "Accessed 4/1/21",
          link: "https://www.nami.org/Your-Journey/Identity-and-Cultural-Dimensions/LGBTQI",
        },
      ],
    },
    {
      title: "Transgender Support",
      link: "/transgender-support",
      background: require("@assets/images/toolkits/toolkit4_bg.png"),
      placeholder: require("@assets/images/toolkits/toolkit4_thumb.png"),
      sources: [
        {
          label: "GLAAD: Tips for Allies of Transgender People.",
          sublabel: "Accessed 4/1/21",
          link: "https://www.glaad.org/transgender/allies",
        },
      ],
    },
    {
      title: "Terminology & Pronouns",
      link: "/terminology-&-pronouns",
      background: require("@assets/images/toolkits/toolkit5_bg.png"),
      placeholder: require("@assets/images/toolkits/toolkit5_thumb.png"),
      sources: [
        {
          label:
            "The Trevor Project: A Guide to Being an Ally to Transgender and Nonbinary Youth.",
          sublabel: "Accessed 4/3/21",
          link: "https://www.thetrevorproject.org/resources/trevor-support-center/a-guide-to-being-an-ally-to-transgender-and-nonbinary-youth/",
        },
        {
          label:
            "GLAAD: Media Reference Guide – Lesbian, Gay, Bisexual Glossary of Terms.",
          sublabel: "Accessed 4/3/21",
          link: "https://www.glaad.org/reference/lgbtq",
        },
        {
          label: "Human Rights Campaign: Glossary of Terms.",
          sublabel: "Accessed 4/3/21",
          link: "https://www.hrc.org/resources/glossary-of-terms",
        },
        {
          label:
            "Human Rights Campaign: All Children All Families: Pronouns 101.",
          sublabel: "Accessed 4/3/21",
          link: "https://assets2.hrc.org/files/assets/resources/HRC_ACAF_Pronouns_101_(1).pdf",
        },
      ],
    },
  ],
  disclaimer:
    "<strong>This program should not be used for emergency or urgent care needs. In an emergency, call 911 or go to the nearest emergency room.</strong> This program is not a substitute for a doctor’s or professional’s care. Consult with your clinician for specific health care needs, treatment or medication.",
};
