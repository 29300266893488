import React from "react";
import Anchor from "@atom/Anchor";
import style from "./style.scss";
import cn from "classnames";

export default ({ name, info, mail }) => (
  <div className={cn(style.email)}>
    <div className={cn(style.name)}>{name}</div>
    <p>{info}</p>
    {/* <Anchor href={`mailto:${mail}`}>{mail}</Anchor> */}
    <label>{mail}</label>
  </div>
);
