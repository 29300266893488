import React from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import Icon from "@atom/Icon";
import "react-h5-audio-player/src/styles.scss";
import style from "./style.scss";
import cn from "classnames";

export default ({ src, className }) => (
  <div className={cn(style.audio, className)}>
    <AudioPlayer
      autoPlay={false}
      layout={"horizontal-reverse"}
      customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
      customProgressBarSection={[
        RHAP_UI.PROGRESS_BAR,
        RHAP_UI.CURRENT_TIME,
        <div className={cn(style.divide)}>/</div>,
        RHAP_UI.DURATION,
        <div className={cn(style.divide)} />,
        RHAP_UI.VOLUME,
      ]}
      customIcons={{
        play: <Icon icon={"play"} />,
        pause: <Icon icon={"pause"} />,
        volume: <Icon icon={"volume"} />,
        volumeMute: <Icon icon={"mute"} />,
      }}
      customAdditionalControls={[]}
      showSkipControls={false}
      showJumpControls={false}
      src={src}
    />
  </div>
);
