import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ProgressiveImage from "@atom/ProgressiveImage";
import { Heading, LabelwithEmphasis } from "@atom/Typography";
import Navigation from "@molecule/Navigation";
import PageCard from "@molecule/PageCard";
import Sources from "@molecule/Sources";
import Ally from "@organism/Ally";
import FactSheets from "@organism/FactSheets";
import ResourceGuide from "@organism/ResourceGuide";
import Terminology from "@organism/Terminology";
import TransgenderSupport from "@organism/TransgenderSupport";
import data from "./data";
import style from "./style.scss";
import cn from "classnames";

export default () => {
  let location = useLocation().pathname;
  const [active, setActive] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const nextActive = data.items.findIndex((item) => item.link === location);
    setActive(nextActive);
  }, [location]);

  return (
    <div className={cn(style.toolkit)}>
      <Navigation {...{ location }} items={data.items} />
      {data.items.map((value, i) => (
        <div
          key={i}
          className={cn(style.container, active === i && style.hide)}
        >
          <div className={cn(style.content)}>
            <PageCard className={cn(style.pageCard)}>
              {value.title === "Facts" ? <FactSheets /> : null}
              {value.title === "How to be an ally" ? <Ally /> : null}
              {value.title === "Resources" ? <ResourceGuide /> : null}
              {value.title === "Transgender Support" ? (
                <TransgenderSupport />
              ) : null}
              {value.title === "Terminology & Pronouns" ? (
                <Terminology />
              ) : null}
            </PageCard>
            <Sources
              {...value}
              sourcesLabel={data.sourcesLabel}
              className={cn(style.sourcesMobile)}
            />
            <LabelwithEmphasis className={cn(style.disclaimer)}>
              {data.disclaimer}
            </LabelwithEmphasis>
          </div>
          <div className={cn(style.background)}>
            <ProgressiveImage
              src={value.background}
              placeholder={value.placeholder}
              alt={value.title}
              width={1920}
              height={815}
            />
            <Sources
              {...value}
              sourcesLabel={data.sourcesLabel}
              className={cn(style.sources)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
