import React from "react";
import { Heading, LabelwithEmphasis } from "@atom/Typography";
import Sources from "@molecule/Sources";
import TitleDescription from "@molecule/TitleDescription";
import data from "./data";
import style from "./style.scss";
import cn from "classnames";

const Hotline = ({ img, label, number, className }) => (
  <div className={cn(style.hotline, className)}>
    <img src={img} alt={label} width={290} height={133} />
    <div className={cn(style.text)}>
      <Heading type={"h5"} weight={900} withMargin={true}>
        {label}
      </Heading>
      <LabelwithEmphasis>{number}</LabelwithEmphasis>
    </div>
  </div>
);

export default () => (
  <div className={cn(style.resourceGuide)}>
    <TitleDescription {...data.heading} />
    <Heading type={"h3"} withMargin={true}>
      {data.crisis}
    </Heading>
    <p>{data.crisisDescription}</p>
    <Hotline
      img={data.trevorLogo}
      label={data.trevorLabel}
      number={data.trevorNumber}
      className={cn(style.trevor)}
    />
    <div className={cn(style.hotlines)}>
      {data.crisisHotlines.map((value, i) => (
        <Hotline {...value} key={i} />
      ))}
    </div>
    <Hotline
      img={data.rainLogo}
      label={data.rainLabel}
      number={data.rainNumber}
      className={cn(style.rain)}
    />
    <Heading type={"h3"}>{data.mental}</Heading>
    <Sources sources={data.mentalSources} className={cn(style.sources)} />
  </div>
);
