import React from "react";
import Audio from "@atom/Audio";
import ProgressiveImage from "@atom/ProgressiveImage";
import { Heading, LabelwithEmphasis } from "@atom/Typography";
import { PdfDownload } from "@molecule/ImagewithLabel";
import PageCard from "@molecule/PageCard";
import Speakers from "@molecule/Speakers";
import Episode3 from "./Episode3";
import Episode4 from "./Episode4";
import style from "./style.scss";
import cn from "classnames";

export default ({
  title,
  speaker,
  image,
  audio,
  articleTitle,
  articleBody,
  speakersbio,
  transcript,
  disclaimer,
  articlePDF,
  downloadLabel = "Download Episode",
  order,
}) => (
  <div className={cn(style.episode)}>
    <div className={cn(style.content)}>
      <PageCard
        pageCardClass={cn(style.pageCard)}
        className={cn(style.padding)}
      >
        <Heading type={"h2"} weight={900}>
          {title}
        </Heading>
        <div className={cn(style.subtitle)}>{speaker}</div>
        <ProgressiveImage {...image} className={cn(style.headerImage)} />
        <Audio src={audio} />
        <div className={cn(style.downloads)}>
          <PdfDownload pdf={transcript}>
            {downloadLabel}&nbsp;{order} - Transcript
          </PdfDownload>
          <PdfDownload pdf={audio}>
            {downloadLabel}&nbsp;{order} - Podcast
          </PdfDownload>
        </div>
        <Heading type={"h2"} weight={900}>
          {articleTitle && articleTitle}
        </Heading>
        {order == 1 ? (
          <LabelwithEmphasis className={cn(style.body)}>
            {articleBody}
          </LabelwithEmphasis>
        ) : order == 2 ? (
          <ProgressiveImage
            {...articleBody}
            className={cn(style.articleImage)}
          />
        ) : order == 3 ? (
          <Episode3 />
        ) : (
          <Episode4 />
        )}
        <PdfDownload pdf={articlePDF} className={cn(style.downloadArticle)}>
          {downloadLabel}&nbsp;{order} - Article
        </PdfDownload>
      </PageCard>
      <Speakers {...{ speakersbio }} className={cn(style.speakerMobile)} />
      <LabelwithEmphasis className={cn(style.disclaimer)}>
        {disclaimer}
      </LabelwithEmphasis>
    </div>
    <Speakers {...{ speakersbio }} className={cn(style.speaker)} />
  </div>
);
