import React from "react";
import Anchor from "@atom/Anchor";
import ProgressiveImage from "@atom/ProgressiveImage";
import { Heading } from "@atom/Typography";
import style from "./style.scss";
import cn from "classnames";

export default ({
  background,
  logo,
  app,
  title,
  subtitle,
  downloadLabel,
  downloadSublabel,
  appstore,
  appLink,
  googleplay,
  googleLink,
  footer,
}) => (
  <div className={cn(style.sanvello)}>
    <ProgressiveImage {...background} className={cn(style.bg)} />
    <div className={cn(style.container)}>
      <div className={cn(style.content)}>
        <div className={cn(style.top)}>
          <img {...logo} alt={title} className={cn(style.logo)} />
          <div className={cn(style.text)}>
            <Heading type={"h2"}>{title}</Heading>
            <div className={cn(style.subtitle)}>{subtitle}</div>
          </div>
        </div>
        <ProgressiveImage {...app} className={cn(style.appMobile)} />
        <div className={cn(style.bottom)}>
          <div className={cn(style.download)}>
            <div type={"h5"} className={cn(style.downloadText)}>
              {downloadLabel}
              <p>{downloadSublabel}</p>
            </div>
            <div className={cn(style.image)}>
              <img
                {...appstore}
                tabIndex={0}
                onClick={() => window.open(appLink)}
              />
              <img
                {...googleplay}
                tabIndex={0}
                onClick={() => window.open(googleLink)}
              />
            </div>
          </div>
          <div className={cn(style.hr)} />
          <div className={cn(style.footer)}>{footer}</div>
        </div>
        <ProgressiveImage {...app} className={cn(style.app)} />
      </div>
    </div>
  </div>
);
