import React from "react";
import ReactPlayer from "react-player";

const videoStyle = {
  position: "relative",
  paddingTop: "56.25%",
  borderRadius: 16,
  overflow: "hidden",
  maxHeight: 360,
};
const playerStyle = { position: "absolute", top: 0, left: 0, border: "none" };

export default ({ url }) => (
  <div style={videoStyle}>
    <ReactPlayer
      url={url}
      controls
      height={"100%"}
      width={"100%"}
      style={playerStyle}
      preload={"auto"}
    />
  </div>
);
