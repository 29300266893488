export default {
  heading: {
    title:
      "<span>I’m she.</span> You’re he. They’re them. <span>People.</span>",
    subtitle: "Let’s talk about how to talk to one another.",
    description:
      "Words are tricky. It can be difficult to find or know the right words, especially because their meanings sometimes change over time, or mean different things to different people or in various contexts. But, understanding that we might not always get it exactly right, it’s still important to try. \n\nThis LGBTQ+ terminology guide can help you communicate more effectively and compassionately to promote an open, safe and respectful world for every person.",
    pdf: "/assets/pdf/P365+_LGBTQ+_Terminology_and_Pronouns_Guide.pdf",
  },
  quotes: [
    {
      title: "Ally",
      description:
        "A term used to describe someone who is actively supportive of LGBTQ+ people.",
    },
    {
      title: "Gender identity",
      description:
        "Describes our internal understanding and experience of our own gender identity. Each person’s experience of their gender identity is unique and personal, and cannot be known simply by looking at a person.",
      moreLabel: "Common Genders Terms",
      list: [
        "<strong>Cisgender</strong> – People whose gender identity aligns with the sex they were assigned at birth.",
        "<strong>Transgender</strong> – People whose gender identity differs from the sex they were assigned at birth. Many transgender people will transition to align their gender expression with their gender identity, however you do not have to transition in order to be transgender.",
        "<strong>Nonbinary</strong> – People who experience their gender identity and/or gender expression as outside of the male-female gender binary.",
        "<strong>Two-Spirit</strong> – A term created by First Nations/Native American/Indigenous peoples whose sexual orientation and/or gender/sex exists in ways that challenge colonial constructions of a gender binary. This term should not be used to describe people who are not First Nations/Native American/Indigenous members.",
      ],
    },
    {
      title: "Gender expression",
      description:
        "Describes the external appearance of one’s gender identity, usually expressed through behavior, clothing, body characteristics or voice, and which may or may not conform to socially defined behaviors and characteristics typically associated with being either masculine or feminine.",
    },
    {
      title: "Sexual orientation",
      description:
        "The scientifically accurate term for an individual’s enduring physical, romantic and/or emotional attraction to others. Note: Avoid the offensive term “sexual preference,” which is used to suggest that being gay, lesbian, or bisexual is voluntary and therefore “curable.”",
      moreLabel: "Common Orientation Terms",
      list: [
        "<strong>Asexual</strong> – An adjective used to describe people who do not experience sexual attraction. A person can also be aromantic, meaning they do not experience romantic attraction. ",
        "<strong>Bisexual, Bi</strong> – A person who has the capacity to form enduring physical, romantic, and/ or emotional attractions to those of the same gender or to those of another gender. People may experience this attraction in differing ways and degrees over their lifetime. ",
        "<strong>Gay</strong> – The adjective used to describe people whose enduring physical, romantic, and/ or emotional attractions are to people of the same sex. Note: Avoid identifying gay people as “homosexuals,” which is an outdated, derogatory term.",
        "<strong>Heterosexual</strong> – An adjective used to describe people whose enduring physical, romantic, and/ or emotional attraction is to people of the opposite sex. Also straight.",
        "<strong>Intersex</strong> – An umbrella term describing people born with reproductive or sexual anatomy and/or a chromosome pattern that can’t be classified as typically male or female.",
        "<strong>Lesbian</strong> – A woman whose enduring physical, romantic, and/or emotional attraction is to other women.",
        "<strong>LGBTQ</strong> – Acronym for lesbian, gay, bisexual, transgender and queer. Sometimes, when the Q is seen at the end of LGBT, it can also mean questioning. LGBT and/or GLBT are also often used.",
        "<strong>Pansexual</strong> – A person who has the potential for emotional, romantic or sexual attraction to people of any gender. Sometimes used interchangeably with bisexual.",
        "<strong>Queer</strong> – An adjective used by some people whose sexual orientation is not exclusively heterosexual. Once considered a pejorative term, queer has been reclaimed by some LGBT people to describe themselves; however, it is not a universally accepted term even within the LGBT community. When Q is seen at the end of LGBT, it typically means queer and, less often, questioning.",
        "<strong>Questioning</strong> – An adjective used to describe people who are in the process of exploring their sexual orientation or gender identity.",
      ],
    },
    {
      title: "Pronouns",
      description:
        "Since some pronouns are gendered (“she/her” and “he/him”), it is important to be intentional about the way we use pronouns as we all work to create as inclusive an environment as possible. Commonly used pronouns include: She/her/hers, He/him/his, They/them/theirs. Listen for and respect how the pronoun someone uses to self-identify. To show your support and understanding, you can also introduce your own pronouns when you meet a person, which gives them the opportunity to share theirs.",
      moreLabel: "Common Pronouns Terms",
      list: [
        "If you are unsure of someone’s pronouns, opt for more gender-neutral language when addressing a group of people or coworkers, such as “team,” “friends” or “y’all.”",
        "Use descriptive language when trying to identify someone, such as “the person on the left in the black jacket.”",
        "If you inadvertently use someone’s incorrect pronoun, it’s OK. Mistakes happen. Apologize, use the correct pronoun, move on and make a mental note to get it right next time.",
      ],
    },
  ],
  collapseLabel: "Collapse",
};
