import React from "react";
import Anchor from "@atom/Anchor";
import { Heading } from "@atom/Typography";
import TitleDescription from "@molecule/TitleDescription";
import data from "./data";
import style from "./style.scss";
import cn from "classnames";

export default () => (
  <div className={cn(style.transgender)}>
    <TitleDescription {...data.heading} />
    <div className={cn(style.transfamilies)}>
      <div className={cn(style.text)}>
        <div className={cn(style.caption)}>{data.transFamiliesSublabel}</div>
        <Anchor href={data.transFamiliesLink} className={cn(style.title)}>
          {data.transFamiliesTitle}
        </Anchor>
        <p>{data.transFamiliesDescription}</p>
      </div>
      <img {...data.transFamiliesImage} alt={data.transFamiliesTitle} />
    </div>
    <div className={cn(style.tips)}>
      <div className={cn(style.hr)} />
      <Heading type={"h3"} withMargin={true}>
        {data.tips}
      </Heading>
      <p>{data.tipsDescription}</p>
      <Anchor href={data.learnMoreLink} className={cn(style.button)}>
        {data.learnMore}
      </Anchor>
    </div>
  </div>
);
