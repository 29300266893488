import React from "react";
import { Heading } from "@atom/Typography";
import Masonry from "@molecule/Masonry";
import style from "./style.scss";
import cn from "classnames";

export default ({ background, title, items }) => (
  <div className={cn(style.toolkits)}>
    <img {...background} className={cn(style.bg)} />
    <div className={cn(style.content)}>
      <Heading type={"h3"} className={cn(style.title)}>
        {title}
      </Heading>
      <Masonry {...{ items }} />
    </div>
  </div>
);
