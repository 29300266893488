export default {
  heading: {
    title:
      "<span>You’re you.</span> Colleague, friend, child, sibling, partner, parent. <span>Person.</span>",
    subtitle: "We respect and support your truth. All are welcome here.",
    description:
      "Transgender and nonbinary people face tremendous challenges and barriers that include stigma, harassment, discrimination, violence, and limited access to healthcare. In fact, 48% of transgender adults report they have considered suicide in the last year, compared to 4 percent of the overall U.S. population.\n\nPlease know support and help are available.",
    pdf: "/assets/pdf/P365+_Transgender_Support.pdf",
  },
  transFamiliesSublabel: "Transfamilies.org",
  transFamiliesTitle: "Trans Families Resources and Services",
  transFamiliesLink: "https://transfamilies.org/",
  transFamiliesDescription:
    "Trans Families inspires hope, increases understanding, and creates a visible pathway to support trans and gender-diverse children and all those who touch their lives.",
  transFamiliesImage: {
    src: require("@assets/images/toolkits/transfam.png"),
    width: 582,
    height: 322,
  },
  tips: "Tips for Allies of Transgender People",
  tipsDescription:
    "When you become an ally of transgender people, your actions will help change the culture, making society a better, safer place for transgender people and for all people (trans or not) who do not conform to conventional gender expectations.",
  learnMore: "Learn More",
  learnMoreLink: "https://www.glaad.org/transgender/allies",
};
