import React from "react";
import LinkButton from "@atom/LinkButton";
import { Heading } from "@atom/Typography";
import data from "./data";
import style from "./style.scss";
import cn from "classnames";

export default () => (
  <div className={cn(style.default)}>
    <Heading type={"h2"} weight={900}>
      Podcasts
    </Heading>
    {data.episodes.map(
      ({ link, title, speaker, description, speakersbio }, i) => (
        <div key={i} className={cn(style.card)}>
          <div className={cn(style.text)}>
            <LinkButton to={link}>
              <Heading type={"h4"} weight={900}>
                {title}
              </Heading>
            </LinkButton>
            <Heading type={"h5"} weight={700} className={cn(style.subtitle)}>
              {speaker}
            </Heading>
            <div className={cn(style.hr)} />
            {description}
          </div>
          <div
            className={cn(
              style.headshots,
              speakersbio.length == 3 && style.wrap
            )}
          >
            {speakersbio.map(({ id }, i) => (
              <img key={i} src={data.speakers[id].image} />
            ))}
          </div>
        </div>
      )
    )}
  </div>
);
