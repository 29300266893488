import React from "react";
import { useHistory } from "react-router-dom";
import LinkButton from "@atom/LinkButton";
import Icon from "@atom/Icon";
import Carousel from "@atom/Carousel";
import { LabelwithEmphasis } from "@atom/Typography";
import Email from "@molecule/Email";
import ImagewithLabel from "@molecule/ImagewithLabel";
import Media from "@molecule/Media";
import PageCard from "@molecule/PageCard";
import style from "./style.scss";
import cn from "classnames";

function Arrows({ className, link = "/", next }) {
  return (
    <div className={className}>
      <LinkButton to={link}>
        <Icon icon={next ? "arrownext" : "arrowprev"} />
      </LinkButton>
    </div>
  );
}

export default ({ items = [], active }) => {
  const history = useHistory();

  return (
    <Carousel
      {...{ active }}
      className={cn(style.pageCarousel)}
      settings={{
        initialSlide: active,
        infinite: false,
        arrows: true,
        nextArrow: <Arrows link={items[active + 1]?.link} next={true} />,
        prevArrow: <Arrows link={items[active - 1]?.link} />,
        beforeChange: (_current, next) => history.replace(items[next].link),
        adaptiveHeight: true,
      }}
    >
      {items.map(
        (
          {
            subtitle,
            description,
            contentType,
            itemTitle,
            items,
            imageFooter,
            footer,
          },
          i
        ) => (
          <div key={i} className={cn(style.container)}>
            <p className={cn(style.subtitle)}>{subtitle} </p>
            <PageCard withBorder withBothShadow className={cn(style.pageCard)}>
              <p>{description}</p>
              <div
                className={cn(
                  style.content,
                  active === i ? style.visible : null
                )}
              >
                {contentType === "logo" ? (
                  <ImagewithLabel {...{ items, imageFooter }} />
                ) : null}
                {contentType === "email" ? <Email {...items} /> : null}
                {contentType === "media" ? (
                  <Media {...{ items, itemTitle }} />
                ) : null}
                {footer && (
                  <LabelwithEmphasis className={cn(style.footer)}>
                    {footer}
                  </LabelwithEmphasis>
                )}
              </div>
            </PageCard>
          </div>
        )
      )}
    </Carousel>
  );
};
