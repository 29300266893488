export default {
  heading: {
    title:
      "<span>It’s me.</span> Colleague, friend, child, sibling, partner. <span>Person.</span>",
    subtitle: "Resources for LGBTQ+ competent care and support",
    description:
      "Do you or someone you care about in the LGBTQ+ community need competent care and support for a situational or chronic mental health condition or substance use disorder? The following are some resources to help you get started – including crisis hotlines, digital, in-person and virtual tools.",
    pdf: "/assets/pdf/P365+_LGBTQ+_Resource_Guide.pdf",
  },
  crisis: "Crisis Hotlines and Text",
  crisisDescription:
    "For you are in immediate danger or having a medical emergency, call 911.\n\n If you are in crisis or thinking about suicide, get in touch with someone immediately:",
  trevorLogo: require("@assets/images/toolkits/trevor_logo.png"),
  trevorLabel:
    "National Youth LGBTQ Crisis Intervention and Suicide Prevention",
  trevorNumber:
    '<a href="tel:+1-866-488-7386">1-866-488-7386</a>\nText START to 678-678',
  crisisHotlines: [
    {
      img: require("@assets/images/toolkits/hotline1.png"),
      label: "SAGE National LGBT Elder Hotline",
      number:
        'Hotline, toll-free, at\n<a href="tel:+1-877-360-5428">877-360-LGBT (5428)</a>',
    },
    {
      img: require("@assets/images/toolkits/hotline2.png"),
      label: "National Suicide Prevention Lifeline",
      number:
        '<a href="tel:+1-800-273-8255">1-800-273-TALK</a>\n(8255) for English\n<a href="tel:+1-888-628-9454">1-888-628-9454</a>\nfor Spanish\nText MHA to 741741',
    },
    {
      img: require("@assets/images/toolkits/hotline3.png"),
      label: "National Domestic Violence Hotline",
      number:
        '<a href="tel:+1-800-799-7233">1-800-799-7233</a>\nText LOVEIS to 22522',
    },
  ],
  rainLogo: require("@assets/images/toolkits/rainn.png"),
  rainLabel: "National Sexual Assault Hotline",
  rainNumber:
    '<a href="tel:+1-800-656-4673"> 1-800-656-HOPE</a> (4673)\n<a href="https://hotline.rainn.org/online" target="_blank">Online Chat</a>',
  mental: "Mental Health and Community Resources",
  mentalSources: [
    {
      label: "The American Psychological Association (APA)",
      sublabel: " – Provides educational and support resources.",
      link: "https://www.apa.org/pi/lgbt/resources/lgbt-health",
    },
    {
      label: "The Association of Gay and Lesbian Psychiatrists",
      sublabel:
        " – Provides mental health resources for LGBTQ+ individuals, including psychiatric professionals with LGBTQ+ clients.",
      link: "https://www.aglp.org/",
    },
    {
      label: "Black Mental Health Alliance (BMHA)",
      sublabel:
        " – Through an expansive database of culturally competent and patient-centered licensed mental health professionals, BMHA offers confidential referrals for those seeking mental health services.",
      link: "https://blackmentalhealth.com/connect-with-a-therapist/",
    },
    {
      label: "CenterLink LGBT Community Center Member Directory",
      sublabel:
        " – Supports the development of strong, sustainable, LGBTQ community centers to address social, cultural, health, and political advocacy.",
      link: "https://www.lgbtcenters.org/LGBTCenters",
    },
    {
      label: "The Gay and Lesbian Medical Association’s Provider Directory",
      sublabel:
        " – A search tool that can locate an LGBTQ-inclusive health care provider.",
      link: "https://www.glma.org/find_a_provider.php",
    },
    {
      label: "The LGBT National Help Center",
      sublabel:
        " – Offers confidential peer support connections for LGBTQ+ youth, adults and seniors, including phone, text and online chat.",
      link: "http://www.glbtnationalhelpcenter.org/",
    },
    {
      label: "National Queer and Trans Therapists of Color Network",
      sublabel:
        " — A healing justice organization committed to transforming mental health for queer and trans people of color. Provides a directory to help queer and trans people of color locate mental health practitioners who are queer and trans people of color.",
      link: "https://www.nqttcn.com/directory",
    },
    {
      label: "The National Center for Transgender Equality",
      sublabel:
        " – Offers resources for transgender individuals, including information on the right to access health care.",
      link: "https://transequality.org/",
    },
    {
      label: "OutCare Health",
      sublabel:
        " – A national nonprofit 501(c)(3) LGBTQ+ health equity organization that provides extensive information and resources on LGBTQ+ healthcare. OutCare has a number of national initiatives, including a directory of LGBTQ+ culturally competent providers (the OutList), LGBTQ+ Public Resource Database, Mentorship Program, the OutTalk series on diversity and intersectionality, OutPost blogs, LGBTQ+ research, LGBTQ+ cultural competency trainings, and many more.",
      link: "https://www.outcarehealth.org/",
    },
    {
      label: "SAGE National LGBT Elder Hotline",
      sublabel:
        " – Provides information about community support resources such as healthcare, transportation, counseling, legal services, and emotional support programs.",
      link: "https://www.sageusa.org/what-we-do/sage-national-lgbt-elder-hotline/",
    },
    {
      label:
        "Society for Sexual, Affectional, Intersex, and Gender Expansive Identities (SAIGE)",
      sublabel:
        " – Offers educational and support resources for LGBTQ+ individuals, and promotes LGBTQ+ competency for counseling professionals.",
      link: "https://saigecounseling.org/",
    },
    {
      label: "Trans Lifeline",
      sublabel: " – Provides trans peer support: (877) 565-8860.",
      link: "https://translifeline.org/",
    },
  ],
};
