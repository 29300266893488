import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Icon from "@atom/Icon";
import Breadcrumbs from "@molecule/Breadcrumbs";
import Sidebar from "@molecule/Sidebar";
import style from "./style.scss";
import cn from "classnames";

export default ({ noBG }) => {
  let location = useLocation().pathname;
  const [open, setOpen] = useState(false);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setOpen(!open);
    }
  };

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <div className={cn(style.menu, noBG && style.noBG)}>
      <div className={cn(style.content)}>
        <Sidebar onClose={() => setOpen(false)} open={open} />
        <div className={cn(style.container)}>
          <div
            className={cn(style.icon, location !== "/" && style.light)}
            tabIndex={"0"}
            onClick={() => setOpen(true)}
            onKeyPress={handleKeyPress}
          >
            <Icon icon={"menu"} />
          </div>
          {!noBG && <Breadcrumbs />}
        </div>
      </div>
    </div>
  );
};
