import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PodcastBG from "@assets/podcasts/podcastbg.svg";
import Episode from "@organism/Episode";
import Default from "./Default";
import data from "./data";
import style from "./style.scss";
import cn from "classnames";

export default () => {
  let location = useLocation().pathname;
  const history = useHistory();
  const [active, setActive] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const second = location.split("/").length;

    if (second == 3) {
      const nextActive = data.episodes.findIndex(
        (episode) => episode.link === location
      );

      if (nextActive >= 0) setActive(nextActive + 1);
      else history.push("/podcasts");
    } else setActive(0);
  }, [location]);

  return (
    <div className={cn(style.podcasts)}>
      <div className={cn(style.bg)}>
        <PodcastBG />
      </div>
      {active ? (
        <Episode {...data.episodes[active - 1]} disclaimer={data.disclaimer} />
      ) : (
        <Default />
      )}
    </div>
  );
};
