import React from "react";
import { Heading, LabelwithEmphasis } from "@atom/Typography";
import style from "./style.scss";
import cn from "classnames";

const details = {
  title: "Beyond the binary:",
  subtitle: "Understanding the diversity of gender and gender-affirming care",
  body1:
    "Most of us are assigned a sex when we’re born: female or male. That’s based on our genitals or chromosomes. Others are born intersex. This means their bodies don’t clearly fit either label. Birth certificates in most states don’t give intersex as a choice. So parents have had to choose between male and female. Often, intersex babies undergo a surgery to align more clearly with the chosen sex.",
  body2:
    "<strong>Sex is about our bodies. It’s a biological discussion. But gender is something different. </strong>Dr. Dustin Nowaskie explored this topic on the Pride 365+ podcast and was joined by Dr. Morissa Ladinsky and Dr. Jenny Siegel.",
  title2: "Defining gender: The basics",
  body3:
    "While sex is about the body, gender is about identity. It’s about our inner sense of ourselves. Gender can be female, male, a blend or neither. For some people, gender matches their assigned sex. That’s called cisgender. For others, it’s different. Transgender people were assigned male at birth but know themselves to be female, or vice versa. The spectrum of gender includes other identities as well. Here are some terms to know: ",
  list: [
    "<strong>Agender</strong> — doesn’t identify with a particular gender",
    "<strong>Bigender</strong> — identifies as two genders",
    "<strong>Genderqueer</strong> — doesn’t subscribe to common male/female gender definitions",
    "<strong>Nonbinary</strong> — doesn’t identify as either male or female",
    "<strong>Genderfluid</strong> — doesn’t identify as having a fixed gender",
  ],
  body4:
    "While some people find terms like these helpful to describe their gender, for others, assigning a name to it isn’t so easy. That’s because gender is a very personal thing. It’s about how we feel, and how we experience gender can evolve over time. Trying to explain those feelings to family, co-workers or others can be hard, especially if they aren’t understanding. And that can leave people feeling isolated or even rejected.",
  quote1: `“We all have our own internal sensation of who we are from a gender perspective. <strong>And that might be a stable thing in life, or that might be an evolving thing in life.</strong>” <br>— <strong>Dr. Jenny Siegel</strong>`,
  title3: "Gender expression",
  body5:
    "<strong>When you think of someone who’s female, what comes to mind?</strong> How about someone who’s male? Our answers have a lot to do with our culture. Our sense of gender can depend on where and how we were raised. That includes what families, communities and religious organizations told us.<br><br> We express gender through what we wear and how we talk, style our hair and interact with others. For some people, there’s a lot of pressure to follow strict rules. Others were given the freedom to explore gender when they were young. And some find the freedom when they get older.<br><br>Dr. Morissa Ladinsky explains, “Between four, five, even six years old, a sense of gender identity fills in. Remember the dress-up box? Did you put on the princess outfit? If you were assigned male at birth, what did your teacher say? Or how did they look? Those are the beginnings of aligning someone’s internal sense of gender with what is available to them to express it.”",
  quote2: `“Gender is somewhat <strong>like waking up each day and looking at which hand we write with.</strong> It’s just a component of who we are.”<br>— <strong>Dr. Morissa Ladinsky</strong>`,
  body6:
    "For those of us who feel like we fit into the binary, male or female, it’s important to understand that gender isn’t as simple as some of us were taught. For many people, it’s more fluid. When we’re open to learning, we can be more understanding. We can help others feel more accepted too.<br><br><strong>As the podcast guests discuss, gender isn’t always easily defined. </strong>And it’s often not a quick, one-time decision. If you’re exploring your own gender, give yourself grace. While some people have a sense of gender early on, others question it for years. Give others that grace too. No matter how we identify, feeling accepted is crucial.",
};
export default () => (
  <div className={cn(style.episode)}>
    <Heading type={"h2"} weight={900}>
      {details.title}
    </Heading>
    <Heading type={"h3"} weight={700} className={cn(style.subtitle)}>
      {details.subtitle}
    </Heading>
    {details.body1}
    <LabelwithEmphasis className={cn(style.body)}>
      {details.body2}
    </LabelwithEmphasis>
    <Heading type={"h3"} weight={900} className={cn(style.title)}>
      {details.title2}
    </Heading>
    {details.body3}
    <ul>
      {details.list.map((value, i) => (
        <li key={i}>
          <LabelwithEmphasis>{value}</LabelwithEmphasis>
        </li>
      ))}
    </ul>
    {details.body4}
    <LabelwithEmphasis className={cn(style.quote)}>
      {details.quote1}
    </LabelwithEmphasis>
    <Heading type={"h3"} weight={900}>
      {details.title3}
    </Heading>
    <LabelwithEmphasis className={cn(style.body)}>
      {details.body5}
    </LabelwithEmphasis>
    <LabelwithEmphasis className={cn(style.quote)}>
      {details.quote2}
    </LabelwithEmphasis>
    <LabelwithEmphasis className={cn(style.body)}>
      {details.body6}
    </LabelwithEmphasis>
  </div>
);
