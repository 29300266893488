export default {
  background: {
    src: require("@assets/images/support_bg.png"),
    placeholder: require("@assets/images/support_thumb.png"),
    width: 1920,
    height: 843,
  },
  items: [
    {
      title: "Poster & Flyer",
      subtitle: "We respect and support your truth.  All are welcome here.",
      description:
        "Download these PDF offerings and share with\ncolleagues, friends, and family.",
      imageFooter: {
        src: require("@assets/images/heartline.png"),
        alt: "Poster & Flyer Footer Image",
        width: 515,
        height: 77,
      },
      contentType: "logo",
      items: [
        {
          image: {
            src: require("@assets/images/poster.png"),
            width: 147,
            height: 127,
          },
          label: "Poster",
          downloadLabel: "Download Here",
          pdf: "/assets/pdf/P365+_Poster.pdf",
          sublabel: "Adobe PDF\n(102Kb)",
        },
        {
          image: {
            src: require("@assets/images/flyer.png"),
            width: 152,
            height: 127,
          },
          label: "Flyer",
          downloadLabel: "Download Here",
          pdf: "/assets/pdf/P365+_Flyer.pdf",
          sublabel: "Adobe PDF\n(90Kb)",
        },
      ],
      link: "/poster-&-flyer",
    },
    {
      title: "Email & Logo",
      subtitle: "Add your pronouns to your auto email signature.",
      description:
        "Add your pronouns to your auto email signature. Another way to support the people you work with is to include your gender pronouns in your auto signature and acknowledge the inclusion in your colleagues' signatures.\n\nGender pronouns are words individuals would like others to use when talking to or about them. You can't always tell what someone's gender pronoun preferences are. People who are transgender or gender nonconforming may choose to use pronouns that don't conform to binary male/female gender categorizations, such as \"they, them, theirs.\"\n\nHere is an auto signature example that includes gender pronouns:",
      contentType: "email",
      items: {
        name: "John Doe",
        info: "Pronouns: he / him / his\n(Job Title) (Company Name)\n555-555-5555 (mobile)",
        mail: "John.Doe@mipride365plus.com",
      },
      link: "/emails",
      footer: `<a href=\"${"/assets/Pride_365+.png"}\" download="Pride_365+" onClick="var that=this;_gaq.push([‘_trackEvent’,’Download’,’PDF’,this.href]);setTimeout(function(){location.href=that.href;},200);return false;">Download the Pride 365+ icon</a> and show your support for the LGBTQ+ community 365 days a year.`,
    },
    {
      title: "Social Media",
      subtitle:
        "Let’s take pride in creating an open, safe and respectful world.",
      description:
        "Social media is a powerful way to speak up and show your pride and your support for the LGBTQ+ community but sometimes finding the right words keeps us quiet when our support is needed most.",
      contentType: "media",
      itemTitle:
        "Here are a few examples of ways to show your support on social media. #mipride365+",
      items: [
        "Love conquers hate. #mipride365+",
        "Proud to be me. #mipride365+",
        "I’m me — your colleague, friend, child, sibling. A human, like you. I’m also part of the LGBTQ+ community. #outandproud #mipride365+",
        "Ally is not a noun. It’s a verb. It requires action. #mipride365+",
        "I stand with the LGBTQ+ community. I am an ally. #mipride365+",
        "Being an ally is a sustained and persistent pattern of action; not an idle or stable noun. Visit mipride365plus.com to learn more. #mipride365+",
        "Listen. Learn. And support your LGBTQ+ family and friends by visiting mipride365plus.com to learn about being an ally. #mipride365+",
        "By listening, respecting boundaries, being an active supporter and normalizing pronouns you are a true ally. #mipride365+",
        "We see you. We value you. You matter. We stand with you. #mipride365+",
      ],
      link: "/media",
    },
  ],
  missionLabel: "Mission and Vision Statement ",
  missionSublabel:
    "Helping people live healthier lives and helping make the health system work better for everyone.\n \nWe strive to create equitable, affirming and supportive environments for our LGBTQ+ members and their families by providing services and resources delivered with dignity, kindness and respect by our knowledgeable and compassionate staff.\n \nWe work to educate our teams, clients and community partners regarding the LGBTQ+ population’s specific mental health and substance use challenges by providing trainings and seminars to address suicide prevention and trauma-informed care, cultural competencies including pronouns and terminology and by growing our LGBTQ+ network of care providers.\n \nAll are welcome here. The Pride 365+ Toolkit Materials provided here are available for all to use. Let’s take pride in creating an open, safe and respectful world.",
};
