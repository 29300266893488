import React from "react";
import Anchor from "@atom/Anchor";
import ProgressiveImage from "@atom/ProgressiveImage";
import { Heading, LabelwithEmphasis } from "@atom/Typography";
import Video from "@atom/Video";
import style from "./style.scss";
import cn from "classnames";

const Service = ({ image, title, description, link }) => (
  <div className={cn(style.service)}>
    <img src={image} alt={title} />
    <Heading type={"h5"} className={cn(style.serviceTitle)}>
      <Anchor href={link}>{title}</Anchor>
    </Heading>
    <LabelwithEmphasis weight={700}>{description}</LabelwithEmphasis>
  </div>
);

export default ({
  background,
  logo,
  logoLabel,
  learnMoreLabel,
  learnMoreLink,
  info,
  infoLabel,
  services,
}) => (
  <div className={cn(style.outcare)}>
    <div className={cn(style.info)}>
      <div type={"h5"} className={cn(style.left)}>
        <img {...logo} />
        <LabelwithEmphasis>{logoLabel}</LabelwithEmphasis>
        <Anchor href={learnMoreLink}>{learnMoreLabel}</Anchor>
      </div>
      <div className={cn(style.right)}>
        <ProgressiveImage {...info} className={cn(style.img)} />
        <LabelwithEmphasis className={cn(style.label)}>
          {infoLabel}
        </LabelwithEmphasis>
      </div>
    </div>
    <img {...background} className={cn(style.bg)} />
    <div className={cn(style.container)}>
      <div className={cn(style.services)}>
        {services.map((value, i) => (
          <Service {...value} key={i} />
        ))}
      </div>
    </div>
  </div>
);
