import React from "react";
import LinkButton from "@atom/LinkButton";
import { Heading } from "@atom/Typography";
import style from "./style.scss";
import cn from "classnames";

export default ({ image, title, description, link }) => (
  <LinkButton to={link} className={cn(style.card)}>
    <img src={image} alt={title} width={96} height={96} />
    <Heading type={"h4"}>{title}</Heading>
    <p>{description}</p>
  </LinkButton>
);
