import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import disableScroll from "disable-scroll";
import Icon from "@atom/Icon";
import LinkButton from "@atom/LinkButton";
import { Heading } from "@atom/Typography";
import data from "./data";
import style from "./style.scss";
import cn from "classnames";

export default ({ open, onClose }) => {
  let location = useLocation().pathname;
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;

  if (!isIE) {
    open ? disableScroll.on() : disableScroll.off();
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      onClose();
    }
  }

  useEffect(() => {
    const e = document.getElementById("side");
    if (open) setTimeout(() => e.focus(), 720);
  }, [open]);

  return (
    <div className={cn(style.container, open ? style.visible : null)}>
      <div className={cn(style.sidebar, open ? style.slideIn : style.slideOut)}>
        <Heading className={cn(style.title)}>{data.title}</Heading>
        <a href={"#"} id={"side"} className={cn(style.side)} />
        {data.links.map(({ link, label, smaller }, i) => (
          <LinkButton key={i} to={link}>
            <Heading
              type={smaller ? "h4" : "h3"}
              className={cn(
                smaller ? style.more : style.toolkit,
                location === link && style.active
              )}
            >
              {label}
            </Heading>
          </LinkButton>
        ))}
      </div>
      <div
        className={cn(style.close, open ? style.visible : null)}
        onClick={onClose}
        onKeyPress={handleKeyPress}
        tabIndex={0}
      >
        <Icon icon={"arrowprev"} />
      </div>
      <div className={cn(style.outOfBounds)} onClick={onClose} />
    </div>
  );
};
