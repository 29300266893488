import React from "react";
import LinkButton from "@atom/LinkButton";
import ProgressiveImage from "@atom/ProgressiveImage";
import Play from "@assets/icons/play_circle.svg";
import style from "./style.scss";
import cn from "classnames";

export default ({ title, image, link }) => (
  <div className={cn(style.podcastCard)}>
    <ProgressiveImage {...image} className={cn(style.bg)} />
    <LinkButton className={cn(style.content)} to={link}>
      {title}
      <Play />
    </LinkButton>
  </div>
);
