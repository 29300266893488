import React from "react";
import Play from "@assets/icons/play_circle.svg";
import LinkButton from "@atom/LinkButton";
import ProgressiveImage from "@atom/ProgressiveImage";
import { Heading } from "@atom/Typography";
import PodcastCard from "@molecule/PodcastCard";
import style from "./style.scss";
import cn from "classnames";

export default ({ background, bottombg, title, subtitle, episodes }) => {
  const featured = episodes[0];

  return (
    <div className={cn(style.podcast)}>
      <div className={cn(style.container)}>
        <div className={cn(style.left)}>
          <ProgressiveImage {...background} className={cn(style.bg)} />
        </div>
        <div className={cn(style.container)}>
          <LinkButton to={"/podcasts"}>
            <Heading type={"h2"} weight={900}>
              {title}
            </Heading>
          </LinkButton>
          <div className={cn(style.subtitle)}>{subtitle}</div>
          <div className={cn(style.featured)}>
            <div className={cn(style.text)}>
              <Heading type={"h5"} weight={900}>
                <LinkButton to={featured.link}>{featured.title}</LinkButton>
              </Heading>
              <div className={cn(style.speaker)}>{featured.speaker}</div>
              {featured.description}
            </div>
            <LinkButton to={featured.link} className={cn(style.image)}>
              <Play />
              <ProgressiveImage {...featured.image} />
            </LinkButton>
          </div>
          <div className={cn(style.others)}>
            {episodes.slice(1).map((value, i) => (
              <PodcastCard {...value} key={i} />
            ))}
          </div>
        </div>
      </div>
      <img {...bottombg} className={cn(style.bottombg)} />
    </div>
  );
};
