import React from "react";
import Anchor from "@atom/Anchor";
import { Heading, LabelwithEmphasis } from "@atom/Typography";
import style from "./style.scss";
import cn from "classnames";

export default ({
  title,
  subtitle,
  description,
  pdf,
  pdfLabel = "Open related PDF",
}) => (
  <div className={cn(style.titleDescription)}>
    <Heading weight={700}>
      <LabelwithEmphasis className={cn(style.title)}>{title}</LabelwithEmphasis>
    </Heading>
    <Heading type={"h5"} className={cn(style.description)}>
      <LabelwithEmphasis className={cn(style.subtitle)}>
        {subtitle}
      </LabelwithEmphasis>
      {description}
    </Heading>
    <Anchor
      href={pdf}
      onClick={"_gaq.push([‘_trackEvent’,’Download’,’PDF’,this.href])"}
    >
      {pdfLabel}
    </Anchor>
  </div>
);
