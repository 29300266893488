import React, { useState } from "react";
import Icon from "@atom/Icon";
import List from "@atom/List";
import { Heading } from "@atom/Typography";
import TitleDescription from "@molecule/TitleDescription";
import data from "./data";
import style from "./style.scss";
import cn from "classnames";

const Quote = ({ title, description, moreLabel, list, collapse }) => {
  const [active, setActive] = useState(false);

  function handleClick() {
    setActive(!active);
  }
  function handleKeyPress(e) {
    if (e.key === "Enter") {
      handleClick();
    }
  }

  return (
    <div className={cn(style.quote, list && style.list)}>
      <Icon icon="quote" className={cn(style.leftIcon)} />
      <Heading type={"h3"} withMargin={true}>
        {title}
      </Heading>
      <p>{description}</p>
      {list ? (
        <div
          className={cn(style.collapse)}
          onClick={handleClick}
          onKeyPress={handleKeyPress}
          tabIndex={0}
        >
          {active ? `- ${collapse} ${moreLabel}` : "+ " + moreLabel}
        </div>
      ) : null}
      {list && (
        <List
          className={cn(style.collapsible, active && style.active)}
          items={list}
        />
      )}
      <Icon icon="quote" className={cn(style.rightIcon)} />
    </div>
  );
};

export default () => (
  <div className={cn(style.terminology)}>
    <TitleDescription {...data.heading} />
    {data.quotes.map((value, i) => (
      <Quote {...value} key={i} collapse={data.collapseLabel} />
    ))}
  </div>
);
