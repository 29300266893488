import React from "react";
import Anchor from "@atom/Anchor";
import { Heading } from "@atom/Typography";
import style from "./style.scss";
import cn from "classnames";

export default ({
  sourcesLabel,
  sources = [],
  substanceLabel,
  helpline,
  className,
}) => (
  <div className={cn(style.sources, className)}>
    {helpline ? (
      <div className={cn(style.helpline)}>
        <Heading type={"h3"}>{substanceLabel}</Heading>
        {helpline.map((value, i) => (
          <p key={i} dangerouslySetInnerHTML={{ __html: value }} />
        ))}
      </div>
    ) : null}
    {sourcesLabel ? <Heading type={"h3"}>{sourcesLabel}</Heading> : null}
    {sources.map(({ label, sublabel, link }, i) => (
      <label key={i}>
        <Anchor href={link}>{label}</Anchor>
        <span>{" " + sublabel}</span>
      </label>
    ))}
  </div>
);
