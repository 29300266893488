export default {
  heading: {
    title:
      "<span>It’s me.</span> Your colleague, friend,  partner. <span>Ally.</span>",
    subtitle: "Help support the LGBTQ+ community.",
    description:
      "Despite the progress made in advancing support for and the civil rights of LGBTQ+ people, the community continues to face stigma, harassment and discrimination. \n\nYou can help create a safer, more open and respectful world. ",
    pdf: "/assets/pdf/P365+_LGBTQ+_Ally_Guide.pdf",
  },
  ally: "Be an Ally",
  allyList: [
    "<span>Show your support – openly and with commitment.</span> Encourage respect and dignity for all people. So, for example, if you hear or see someone saying or doing something offensive or harmful, speak up and address it. Also, keep in mind advocacy is an ongoing need and process – meaning change won’t happen overnight or after one gesture. ",
    "<span>Create a safe space for communication.</span> Listen respectfully and invite open conversation. For example, if you are a parent, talk with and listen to your children about sexual orientation, gender identity and gender expression, so they understand your support for the LGBTQ+ community, and know they are loved however they identify. Also, talk openly about the risks and preventive measures they can take to try to avoid unsafe situations, including with regard to sex and sexually transmitted diseases.",
    "<span>Educate yourself.</span> Learn how to talk to and advocate for the LGBTQ+ community in language that is unbiased, nondiscriminatory and nonjudgmental. (See the Terminology Guide.) Also, take advantage of the many helpful resources in your community and online to increase your understanding of the LGBTQ+ community, the challenges it faces, and how to help promote awareness and support.",
    "<span>Follow the person’s preference.</span> For example, if you don’t know or are unsure of a person’s pronoun, ask. Likewise, respect and keep confidences – it is not your business to inquire or tell others about someone else’s sexual orientation, gender identity or gender expression. ",
    "<span>Put the person first.</span> Choose words with sensitivity, ensuring that the individuality and humanity of people are respected. Do not use a person’s sexual orientation, gender identity or gender expression to define or casually describe them. For example, say “transgender people,” not “the transgenders”.",
    "<span>Avoid assumptions.</span> You cannot tell someone’s sexual orientation or gender identity by looking at them. If, for example, you are addressing or referring to someone, use the person’s name or identify them in an unbiased way, such as “the person in the blue button-up shirt”. ",
  ],
  biases: "Know and Face Your Own (Unconscious) Biases",
  biasesDescription:
    "Discrimination often stems from fear and misunderstanding.\n\nSo, for example, ask yourself if you:",
  biasesList: [
    "See things as simpler if you know less about them?",
    "Prefer those most like you?",
    "Favor information that supports your opinions and existing beliefs?",
    "Hold and/or act on assumptions based only on appearances and first impressions?",
    "Gravitate toward things and ideas you already know you like and are interested in?",
  ],
  biasesFooter:
    "These are all indicators of potential biases. We all have them, because our brains create categories to make sense of the world. But the values we place on different categories are learned – and can be unlearned with self-awareness and effort. ",
  biasesActions: [
    {
      label: "Beware of Microaggressions",
      description:
        "These are everyday verbal, nonverbal and environmental slights, snubs, or insults, whether intentional or unintentional, that demean people by suggesting they don’t belong or invalidating their experiences. For example, asking a male colleague if he has a girlfriend or wife, assumes he is heterosexual. ",
    },
    {
      label: "Apologize",
      description:
        "Even with the best intentions, we sometimes make mistakes or accidentally hurt another person’s feelings. So, for example, if you call someone by the wrong pronoun, apologize and take responsibility. Also, be kind to yourself. Everyone makes mistakes – the important part is learning from them and committing to doing better.",
    },
  ],
};
