import React, { useState } from "react";
import { Heading } from "@atom/Typography";
import data from "@screen/Podcasts/data";
import style from "./style.scss";
import cn from "classnames";

export default ({ speakersbio = [], className }) => (
  <div className={cn(style.speakers, className)}>
    <Heading type={"h5"} className={cn(style.title)} weight={900}>
      {data.speakersLabel}
    </Heading>
    {speakersbio.map(({ id, role }, i) => {
      const [active, setActive] = useState(false);

      function handleClick() {
        setActive(!active);
      }
      function handleKeyPress(e) {
        if (e.key === "Enter") {
          handleClick();
        }
      }

      return (
        <div key={i} className={cn(style.single)}>
          <img src={data.speakers[id].image} />
          <div key={i} className={cn(style.text)}>
            <div className={cn(style.role)}>{role}</div>
            {data.speakers[id].name}
            <div className={cn(style.short, !active && style.notActive)}>
              {data.speakers[id].short}
            </div>
            <div className={cn(style.collapsible, active && style.active)}>
              {data.speakers[id].long}
            </div>
            <div
              className={cn(style.fullbio)}
              onClick={handleClick}
              onKeyPress={handleKeyPress}
              tabIndex={0}
            >
              {"Full Bio"}
            </div>
          </div>
        </div>
      );
    })}
  </div>
);
