import React from "react";
import ProgressiveImage from "@atom/ProgressiveImage";
import { Heading } from "@atom/Typography";
import Card from "@molecule/Card";
import style from "./style.scss";
import cn from "classnames";

export default ({ title, background, items }) => (
  <div className={cn(style.supportMaterials)} id={"supportMaterials"}>
    <ProgressiveImage {...background} className={cn(style.img)} />
    <Heading type={"h3"} className={cn(style.title)}>
      {title}
    </Heading>
    <div className={cn(style.container)}>
      {items.map((value, i) => (
        <Card {...value} key={i} />
      ))}
    </div>
  </div>
);
