import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/features/url-search-params";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./theme.scss";
import "@theme/root.scss";

import React from "react";
import { render } from "react-dom";
import App from "./App.js";

render(<App />, document.getElementById("root"));
