export default {
  home: {
    title: "Are you ready to invite others in and share your truth?",
    subtitle:
      "If you’re deciding whether to share your sexual orientation or gender identity with someone, it is important you feel emotionally ready – and safe – doing so. Here are some resources to help you.",
    background: {
      src: require("@assets/images/worksheet.png"),
      placeholder: require("@assets/images/worksheet_thumb.png"),
      width: 424,
      height: 448,
    },
    items: [
      {
        image: {
          src: require("@assets/images/article1.jpg"),
          placeholder: require("@assets/images/article1_thumb.jpg"),
          alt: "Sharing an important part of me. Article",
          width: 320,
          height: 140,
        },
        title: "Sharing an important part of me.",
        subtitle:
          "It's up to you whether you tell someone you identify as part of the LGBTQ+ community. Here are some things to consider if unsure about how to start the conversation.",
        pdfLabel: "Read article",
        pdf: "/assets/pdf/P365+_Article.pdf",
      },
      {
        image: {
          src: require("@assets/images/article2.jpg"),
          placeholder: require("@assets/images/article2_thumb.jpg"),
          alt: "Pride Worksheet",
          width: 320,
          height: 140,
        },
        title: "Should I tell them?",
        subtitle:
          "Deciding to tell someone about your sexual orientation or gender identity is a very personal decision. Explore what's best for you.",
        link: {
          label: "View worksheet",
          url: "/worksheet",
        },
      },
    ],
    trevor: {
      logo: {
        src: require("@assets/images/trevorlogo.png"),
        placeholder: require("@assets/images/trevorlogo_thumb.png"),
        alt: "Trevor Logo",
        width: 344,
        height: 148,
      },
      link: {
        label:
          "The Trevor Project: 2022 National Survey on LGBTQ Youth Mental Health",
        url: "https://www.thetrevorproject.org/survey-2022/",
      },
      images: [
        {
          larger: {
            src: require("@assets/images/trevor_graph1.png"),
            width: 508,
            height: 508,
          },
          smaller: {
            src: require("@assets/images/trevor_graph1_small.png"),
            width: 312,
            height: 312,
          },
          placeholder: require("@assets/images/trevor_graph1_thumb.png"),
          alt: "Trevor Statistics",
        },
        {
          larger: {
            src: require("@assets/images/trevor_graph2.png"),
            width: 508,
            height: 508,
          },
          smaller: {
            src: require("@assets/images/trevor_graph2_small.png"),
            width: 312,
            height: 312,
          },
          placeholder: require("@assets/images/trevor_graph2_thumb.png"),
          alt: "Trevor Statistics",
          width: 312,
          height: 312,
        },
      ],
    },
  },
  background: {
    src: require("@assets/images/support_bg.png"),
    placeholder: require("@assets/images/support_thumb.png"),
    width: 1920,
    height: 843,
  },
  title: "Should I tell them?",
  subtitle:
    "Deciding to tell someone about your sexual orientation or gender identity is a very personal decision. Explore what's best for you.",
  download: {
    label: "Download Worksheet PDF",
    pdf: "/assets/pdf/P365+_Worksheet.pdf",
  },
  questions: [
    "1. Why do I want this person to know?",
    "2. What do I want to say?",
    "3. How do I think they'll react?",
    "4. How does that make me feel?",
    "5. Does telling them put me at any risk",
    "6. Who can I go to who will help me feel safe?",
    "7. Am I ready to open up myself like this?",
  ],
  printLabel: "Print Worksheet",
  prideLogo: require("@assets/images/Pride_365+.png"),
  footer: [
    "<strong>This program should not be used for emergency or urgent care needs. In an emergency, call 911 or go to the nearest emergency room.</strong> This program is not a substitute for a doctor’s or professional’s care. Consult with your clinician for specific health care needs, treatment or medication.",
  ],
};
