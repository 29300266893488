import React from "react";
import { LabelwithEmphasis } from "../Typography";
import style from "./style.scss";
import cn from "classnames";

export default ({ items, className }) => (
  <ul className={cn(style.list, className)}>
    {items.map((value, i) => (
      <li key={i}>
        <LabelwithEmphasis>{value}</LabelwithEmphasis>
      </li>
    ))}
  </ul>
);
