import React from "react";
import LinkButton from "@atom/LinkButton";
import { Heading } from "@atom/Typography";
import style from "./style.scss";
import cn from "classnames";

export default ({ items, location }) => (
  <div className={cn(style.navigation)}>
    {items.map(({ title, link }, i) => (
      <LinkButton
        key={i}
        to={link}
        className={cn(location === link && style.active)}
      >
        <Heading type={"h5"} weight={900} className={cn(style.label)}>
          {title}
        </Heading>
      </LinkButton>
    ))}
  </div>
);
