import React from "react";
import Banner from "@organism/Banner";
import Outcare from "@organism/Outcare";
import Podcast from "@organism/Podcast";
import Sanvello from "@organism/Sanvello";
import SupportMaterials from "@organism/SupportMaterials";
import Toolkits from "@organism/Toolkits";
import Worksheet from "@organism/Worksheet";
import dataPodcast from "../Podcasts/data";
import dataWorksheet from "../Worksheet/data";
import data from "./data";

export default () => (
  <div style={{ width: "100%" }}>
    <Banner banners={data.banners} />
    <Worksheet {...dataWorksheet.home} />
    <Podcast {...dataPodcast} />
    <Toolkits {...data.toolkit} />
    {/* <Sanvello {...data.sanvello} /> */}
    <Outcare {...data.outcare} />
    <SupportMaterials {...data.supportMaterials} />
  </div>
);
