import React from "react";
import style from "./style.scss";
import cn from "classnames";

const main = "© 2023 miPride 365. All rights reserved.";
const alternative = "© 2023 miPride 365. All rights reserved.";

const Footer = ({ mainText }) => (
  <div className={cn(style.root)}>
    <div className={cn(style.footer, !mainText && style.alternative)}>
      <p>{mainText ? main : alternative}</p>
    </div>
  </div>
);

export default Footer;
