import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Heading } from "@atom/Typography";
import ProgressiveImage from "@atom/ProgressiveImage";
import Navigation from "@molecule/Navigation";
import PageCarousel from "@organism/PageCarousel";
import data from "./data";
import style from "./style.scss";
import cn from "classnames";

export default () => {
  let location = useLocation().pathname;
  const [active, setActive] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const nextActive = data.items.findIndex((slide) => slide.link === location);
    setActive(nextActive);
  }, [location]);

  return (
    <div className={cn(style.support)}>
      <ProgressiveImage {...data.background} className={cn(style.bg)} />
      <div className={cn(style.text)}>
        <Heading type={"h3"}>{data.missionLabel}</Heading>
        <p>{data.missionSublabel}</p>
      </div>
      <Navigation {...{ location }} items={data.items} />
      <PageCarousel {...{ active, setActive }} items={data.items} />
    </div>
  );
};
